<template>
  <section class="banner-resolve-box" :key="bannerId">
    <el-carousel height="640px" v-if="bannerList.length">
      <el-carousel-item v-for="(item, index) in bannerList" :key="index">
        <div class="banner-msg-box">
          <div class="banner-resolve-text" :class="'left-style'+bannerId">
            <h3 class="banner-title" v-html="item.title"></h3>
            <p class="banner-desc over-text4" v-html="item.text"></p>
            <div class="custom-btn" v-if="item.buttonText">
              {{item.buttonText}} <el-icon size="18" style="position: relative; top:2px; margin-left: 5px;">
                <ArrowRight />
              </el-icon>
            </div>
          </div>
          <div class="right-box" :class="'banner-style'+bannerId">
            <img :src="item.floatImg" alt="">
          </div>
        </div>
        <img class="banner-img" :src="item.img" alt="">
      </el-carousel-item>
    </el-carousel>
  </section>
</template>
<script setup>
import { ref, reactive, computed, watch, defineProps, toRefs } from 'vue'
import { queryBannerSelect } from '@/api/publish'
const props = defineProps({
  list: {
    type: Array,
    default: () => ([])
  },
  bannerId: {
    type: Number,
    default: 1
  },
  position: {
    type: Number,
    default: 0
  }
})
const { list, bannerId, position } = toRefs(props)
const bannerList = ref([])
watch(position, (newval) => {
  bannerList.value = []
  queryBannerSelect({
    page: 1,
    size: 10,
    position: position.value,
    type: 0
  }).then(res => {
    bannerList.value = res.data.data
  })
}, { immediate: true })


</script>
<style lang='scss' scoped>
.banner-resolve-box {
  overflow: hidden;
  height: 640px;
}
.banner-img {
  height: 640px;
}
@keyframes leftMove {
  from {
    left: -500px;
  }
  to {
    left: 0;
  }
}
$right1: -100px;
$right2: 20px;
$right3: 15px;
$right4: -10px;
$right5: -100px;
@keyframes rightMove1 {
  from {
    right: -500px;
  }
  to {
    right: $right1;
  }
}
@keyframes rightMove2 {
  from {
    right: -500px;
  }
  to {
    right: $right2;
  }
}
@keyframes rightMove3 {
  from {
    right: -500px;
  }
  to {
    right: $right3;
  }
}
@keyframes rightMove4 {
  from {
    right: -500px;
  }
  to {
    right: $right4;
  }
}
@keyframes rightMove5 {
  from {
    right: -500px;
  }
  to {
    right: $right5;
  }
}
.banner-msg-box {
  position: absolute;
  top: $headerHeight + 20px;
  left: 50%;
  transform: translateY(-50%);
  box-sizing: border-box;
  width: 1200px;
  margin-left: -600px;
  z-index: 10;
  color: #fff;
  .banner-resolve-text {
    position: absolute;
    top: 100px;
    width: 540px;
    > h3 {
      font-size: 44px;
    }
    > p {
      margin: 20px 0 80px;
      font-size: 14px;
      line-height: 1.7em;
    }
  }
  .custom-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 40px;
    line-height: 40px;
    border: 1px solid #fff;
    cursor: pointer;
  }
  .right-box {
    position: absolute;
  }
}
.left-style {
  &1,
  &2,
  &3,
  &4,
  &5 {
    animation: leftMove 2s;
  }
}
.banner-style1 {
  right: $right1;
  animation: rightMove1 2s;
}
.banner-style2 {
  top: -20px;
  right: $right2;
  animation: rightMove2 2s;
}
.banner-style3 {
  top: 25px;
  right: $right3;
  animation: rightMove3 2s;
}
.banner-style4 {
  top: 60px;
  right: $right4;
  animation: rightMove4 2s;
}
.banner-style5 {
  top: 70px;
  right: $right5;
  animation: rightMove5 2s;
}
</style>