<template>
  <bannerBox :list="data.banner" :bannerId="data.id" :position="data.bannerId"></bannerBox>
  <div class="usually-box">
    <template v-for="(item, index) in data.details">
      <left-box :data="item" v-if="item.type === 'left'"></left-box>
      <right-box :data="item" v-else-if="item.type === 'right'"></right-box>
      <frameBox :img="item.img" v-else-if="item.type === 'img'"></frameBox>
    </template>
  </div>
</template>
<script setup>
import { ref, reactive, computed, watch } from 'vue'
import bannerBox from './components/banner.vue'
import { onBeforeRouteUpdate } from 'vue-router'
import solveData from "./config"
import { useRoute } from "vue-router"
import leftBox from "./components/leftBox.vue"
import frameBox from "./components/frame.vue"
import rightBox from "./components/rightBox.vue"

const route = useRoute()
let id = ref(route.query ? route.query.id || 1 : 1)
const data = computed(() => {
  return solveData.find(i => {
    return i.id == id.value
  })
})


watch(route, (newval) => {
  id.value = newval.query ? newval.query.id || 1 : 1
})

</script>
<style lang='scss' scoped>
.usually-box {
  padding-bottom: 50px;
  background-color: #fff;
}
</style>