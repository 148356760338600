<template>
  <section class="left-text-box">
    <h3 class="title">{{data.title}}</h3>
    <div class="details-box">
      <ul class="text-desc intro-list">
        <li v-for="(item, index) in data.list" :key="index">
          <h3>{{item.name}}</h3>
          <p>{{item.desc}}</p>
        </li>
      </ul>
      <img class="det-img" :src="data.img" alt="">
    </div>
  </section>
</template>
<script setup>
import { ref, reactive, computed, toRefs } from 'vue'
import { defineProps } from 'vue'
const props = defineProps({
  data: {
    type: Object,
    default: () => ({})
  }
})

const { data } = toRefs(props)


</script>
<style lang='scss' scoped>
$borderColor: #0766e1;
.left-text-box {
  background-color: #fff;
  .title {
    padding: 92px 0 65px;
    font-size: 42px;
    color: #202020;
    text-align: center;
  }
  .details-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .text-desc {
    width: 600px;
    flex-shrink: 0;
    h3 {
      margin-bottom: 26px;
      font-size: 28px;
      font-weight: normal;
      color: #202020;
    }
    p {
      padding-bottom: 24px;
      font-size: 18px;
      color: #808080;
      &:hover {
        color: #333;
      }
    }
  }
  .det-img {
    width: 467px;
  }
}
.intro-list {
  > li {
    position: relative;
    padding-left: 44px;
    $top: 10px;
    &::before {
      position: absolute;
      top: $top;
      left: 0;
      z-index: 10;
      padding: 3px;
      width: 10px;
      height: 10px;
      background-clip: content-box;
      border: 1px solid $borderColor;
      background-color: $borderColor;
      content: "";
    }
    &:not(:last-child) {
      &::after {
        position: absolute;
        top: 18px + $top;
        bottom: -$top;
        left: 8px;
        z-index: 1;
        width: 1px;
        background-color: $borderColor;
        content: "";
      }
    }
  }
}
</style>