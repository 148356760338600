<template>
  <section class="framework">
    <h3 class="title">产品架构</h3>
    <el-image :src="img" :preview-src-list="imgList" />
  </section>
</template>
<script setup>
import { ref, reactive, computed, toRefs } from 'vue'
import { defineProps } from 'vue'
const props = defineProps({
  img: {
    type: String,
    default: ''
  }
})

const { img } = toRefs(props)
const imgList = computed(() => [img.value])

</script>
<style lang='scss' scoped>
.title {
  padding: 92px 0 65px;
  font-size: 42px;
  color: #202020;
  text-align: center;
}
</style>